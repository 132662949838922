import http from '../http'

export default {
  getToken (userLogin) {
    return http.post('/token', userLogin)
  },
  logout (token) {
    if (token === null) {
      return 'Token supllied is null'
    }
    const bearerIndex = token.indexOf('Bearer')
    if (bearerIndex !== -1) {
      token = token.substring(6, token.length)
    }
    return http.get(`/token/logout/${token}`)
  },
  submitotp (payload) {
    return http.get(`/link/${payload.sessionId}/${payload.pin}`)
  },
  submitpin (payload) {
    return http.get(`/link/pin/${payload.sessionId}/${payload.pin}`)
  }
}
