import Vue from 'vue'
import store from './store'

Vue.mixin({
  beforeCreate () {
    const options = this.$options
    if (options.can) {
      this.$can = options.can
    } else if (options.parent && options.parent.$can) {
      this.$can = options.parent.$can
    }
  }
})

export default function can (permission) {
  if (store.state.session.user === null || !store.state.session.user.permissions) {
    return false
  }
  return store.state.session.user.permissions.includes(permission)
}
